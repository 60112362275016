import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Icon, Image, Section } from "@quarkly/widgets";
import { MdLocationOn, MdPhone } from "react-icons/md";
const defaultProps = {
	"padding": "65px 0 65px 0",
	"sm-padding": "60px 0 60px 0",
	"quarkly-title": "Description-9"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"sm-margin": "0px 0px 30px 0px",
			"sm-padding": "0px 0px 0px 0px",
			"align-items": "flex-start",
			"lg-align-items": "center",
			"flex-direction": "column",
			"lg-width": "100%",
			"lg-margin": "0px 0px 0px 0px",
			"padding": "24px 24px 24px 24px",
			"justify-content": "center",
			"display": "flex",
			"width": "50%"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 36px 0px",
			"color": "--darkL2",
			"font": "--headline2",
			"lg-text-align": "center",
			"sm-font": "normal 700 32px/1.2 \"Source Sans Pro\", sans-serif",
			"children": "Contattaci"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 2rem 0px",
			"color": "--grey",
			"font": "--lead",
			"lg-text-align": "center",
			"children": "Pronto per accelerare la tua avventura? Contattaci oggi per prenotare la tua corsa!"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto",
			"display": "flex",
			"padding": "10px 0 10px 0",
			"border-color": "rgba(74, 74, 108, 0.4)",
			"border-radius": "25px",
			"lg-flex-direction": "column",
			"width": "600px",
			"sm-padding": "15px 10px 15px 10px",
			"md-width": "100%",
			"font": "--lead"
		}
	},
	"icon": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdLocationOn,
			"size": "44px",
			"color": "--light",
			"margin": "0px 25px 0px 0px",
			"lg-margin": "0px 0 28px 0px",
			"background": "--color-green",
			"padding": "10px 10px 10px 10px",
			"border-radius": "4px",
			"sm-margin": "0px 0 18px 0px"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"color": "--darkL1",
			"font": "--headline3",
			"children": "Via Giardino, 20, 98065 Montalbano Elicona ME, Italy"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "auto",
			"display": "flex",
			"padding": "10px 0 10px 0",
			"border-color": "rgba(74, 74, 108, 0.4)",
			"border-radius": "25px",
			"lg-flex-direction": "column",
			"width": "600px",
			"sm-padding": "15px 10px 15px 10px",
			"md-width": "100%"
		}
	},
	"icon1": {
		"kind": "Icon",
		"props": {
			"category": "md",
			"icon": MdPhone,
			"size": "44px",
			"color": "--light",
			"margin": "0px 25px 0px 0px",
			"lg-margin": "0px 0 28px 0px",
			"background": "--color-green",
			"padding": "10px 10px 10px 10px",
			"border-radius": "4px",
			"sm-margin": "0px 0 18px 0px"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 12px 0px",
			"color": "--darkL1",
			"font": "--headline3",
			"children": "+393204441666"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"width": "50%",
			"lg-width": "100%",
			"margin": "0px 0px 0px 0px",
			"padding": "24px 24px 24px 24px",
			"sm-padding": "0px 0px 0px 0px",
			"sm-margin": "0px 0px 30px 0px",
			"flex-direction": "column",
			"display": "flex"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"transform": "translateY(0px)",
			"hover-transform": "translateY(-10px)",
			"width": "100%",
			"height": "auto",
			"overflow-y": "hidden",
			"padding": "0px 0px 100% 0px",
			"overflow-x": "hidden",
			"position": "relative",
			"transition": "transform 0.2s ease-in-out 0s"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"object-fit": "cover",
			"display": "block",
			"width": "100%",
			"right": 0,
			"src": "https://uploads.quarkly.io/6639d7741d712a00233396a9/images/1-1.jpg?v=2024-05-07T12:36:57.694Z",
			"position": "absolute",
			"top": "auto",
			"left": 0,
			"bottom": "0px",
			"min-height": "100%"
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text")} />
			</Box>
			<Text {...override("text1")} />
			<Box {...override("box2")}>
				<Icon {...override("icon")} />
				<Box {...override("box3")}>
					<Text {...override("text2")} />
				</Box>
			</Box>
			<Box {...override("box4")}>
				<Icon {...override("icon1")} />
				<Box {...override("box5")}>
					<Text {...override("text3")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box6")}>
			<Box {...override("box7")}>
				<Image {...override("image")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;